import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const Container = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    margin:  0.625rem 0;
    flex-wrap: wrap;

    @media only screen and (min-width: ${breakpoints.lg}) {
        margin:  0 0 0.625rem 0 ;
        height: 3.125em;
    }
`;

const Arrow = styled.svg`
    height: 20px;
    width: 20px;
    fill: #404040;
    position: absolute;
    right: 1.25rem;
`;

const Dropdown = styled.div`
    width: 50%;
    box-sizing: border-box; 

    @media only screen and (min-width: ${breakpoints.lg}) {
       width: initial;

        &.open .pannel {
            max-height: 500px;
        }
    }

    &.open .pannel {
        display: block
    }

    .pannel {
        height: 100vh;
        width: 100vw;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99990;
        display: none;
        transition: max-height 0.5s ease;
        overflow: hidden;
        background: #F5F4F3;
        box-sizing: border-box;
      
        @media only screen and (min-width: ${breakpoints.lg}) {
            box-shadow: 0 14px 1rem rgb(0 0 0 / 30%);
            position: absolute;
            max-height: 0;
            z-index: 100;
            width: 100%;
            height: auto;
            display: block;
            top: unset;
        }

        .pad {
            padding: 1.25rem;
            height: 100%;
            @media only screen and (min-width: ${breakpoints.lg}) {
                height: initial;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            margin: 0;
            
            li {
                position: relative;
                padding: 0 0 .5rem 2rem;

                input {
                    display: none;
                }

                label {
                    cursor: pointer;
                    line-height: 1.563rem;
                    font-size: 0.9rem;

                    &:after {
                        content: "";
                        height: 1.563rem;
                        width: 1.563rem;
                        border: 1px solid #D8D8D8;
                        position: absolute;
                        left: 0;
                    }

                    svg {
                        height: 1.25rem;
                        fill: var(--primary);
                        display: none;
                        position: absolute;
                        left: .25rem;
                        top: .25rem;
                    }
                }

                input:checked + label{
                    &:after {
                        border: 1px solid var(--primary);
                    }

                    svg {
                       display: inline; 
                    }
                }
            }
        }
        
        h3 {
            margin: 0 0 1.25rem 0;
        }
    }
`;
const ButtonContainer = styled.div`
    width: 100%;
    margin: 75px 0 0 0;
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 1.25rem;
    right: 1.25rem;

    @media only screen and (min-width: ${breakpoints.lg}) {
        position: relative;
    }
`;

const Button = styled.button`
    background: transparent;
    border: 1px solid #404040;
    color: #404040;
    cursor: pointer;
    box-sizing: border-box;
    padding: 15px 45px;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.875rem;
    text-decoration: none;
    margin: 0 10px 0 0;
    
    &.apply {
        background: var(--primary);
        color: white;
        border: none;
        transition: background .5s ease;

        &:hover {
            background: var(--lightenPrimary);
        }
    }
`;

const Select = styled.div`
    background: #F5F4F3;
    border: 1px solid lightgrey;
    height: 3.125rem;
    position: relative;
    margin: 0 0 0 .3125rem; 
    display: flex;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    transition: background 0.5s ease;
  
    select {
      text-transform: capitalize;
    }

    @media only screen and (max-width: ${breakpoints.lg}) {
        &:last-of-type {
            margin: 0 .3125rem 0 0; 
        }
    }

    @media only screen and (min-width: ${breakpoints.lg}) {
        width: 11.875rem;
        margin: 0 .625rem 0 0; 
        height: 100%;
    }

    .open & {
        background: #F5F4F3;
    }
    span, select {
        padding-left: 1.25rem;
        font-weight: 700;
        font-size: .85rem;
        color: #404040;
        background: none;
        border: none;
    }

    select {
        height: 100%;
        width: 80%;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        white-space: nowrap;
        text-overflow: ellipsis;

        &::-ms-expand {
            display: none;
        }
    }
`;
const Total = styled.p`
    display: flex;
    align-items: center;
    font-size: .85rem;
    font-weight: 700;
    color: var(--secondary);
    margin: 0.625rem 0 0 0%;
    
    @media only screen and (min-width: ${breakpoints.lg}) {
        margin: 0;
    }
`;

const Filters = ({unexpectedRegion, setUnexpectedRegion, setFilters, filters, setRegion, region, total, handleLoad, tempFilters, setTempFilters, setCurrentPage}) => {
    if(region && !unexpectedRegion) { 
        region = region.toLowerCase();
    }

    const updateRegion = () => {
        const {value} = document.querySelector("select[name=\"region\"]");
        setRegion(value);
        setUnexpectedRegion(false);
        setCurrentPage(1);
        handleLoad();
    };

    const handleDropdown = (e) => {
        e.target.closest(".filters").classList.toggle("open");
    };

    const handleFilters = (e, filter) => {
        let tempArr = tempFilters;

        if (filter === "apply") {
            setFilters( [...tempArr]);
            handleDropdown(e);
            handleLoad();
            setCurrentPage(1);
        } else  if (filter === "close") {
            setTempFilters( [...filters]);
            handleDropdown(e);
        } else {
            if (tempArr.includes(filter)) {
                tempArr = tempArr.filter((value) => {
                    return value !== filter;
                });
            } else {
                tempArr.push(filter);
            }
            setTempFilters( [...tempArr]); 
        }
    };

    return (
        <Container>
            <Dropdown className="region">
                <Select>
                    <select name="region" onChange={updateRegion}> 
                        {unexpectedRegion && <option selected>{region}</option>}
                        <option selected={!region} value="">All regions</option>
                        <option selected={region ? region.includes("east england") : false} value="east england">East England</option>
                        <option selected={region ? region.includes("east midlands") : false} value="east midlands">East midlands</option>
                        <option selected={region ? region.includes("ireland") : false} value="ireland">Ireland</option>
                        <option selected={region ? region.includes("mid wales") : false} value="mid wales">Mid Wales</option>
                        <option selected={region ? region.includes("north east england") : false} value="north east england">North East England</option>
                        <option selected={region ? region.includes("north wales") : false} value="north wales">North Wales</option>
                        <option selected={region ? region.includes("north west england") : false} value="north west england">North West England</option>
                        <option selected={region ? region.includes("scotland") : false} value="scotland">Scotland</option>
                        <option selected={region ? region.includes("south east") : false} value="south east">South East</option>
                        <option selected={region ? region.includes("south wales") : false} value="south wales">South Wales</option>
                        <option selected={region ? region.includes("south west") : false} value="south west">South West</option>
                        <option selected={region ? region.includes("west midlands") : false} value="west midlands">West Midlands</option>
                        <option selected={region ? region.includes("yorkshire") : false} value="yorkshire and the humber">Yorkshire and the Humber</option>   
                    </select>
                    <Arrow viewBox="0 0 24 24">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/>
                        </g>
                    </Arrow>
                </Select>
            </Dropdown> 
            <Dropdown className="filters">
                <Select onClick={(e) => handleDropdown(e)}>
                    <span>Filters {filters.length > 0 ? `(${filters.length})` : ""}</span>
                    <Arrow viewBox="0 0 24 24">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"/>
                        </g>
                    </Arrow>
                </Select>
                <div className="pannel">
                    <div className="pad">
                        <h3>Features</h3>
                        <ul>
                            <li>
                                <input type="checkbox" id="pets" name="pets" checked={tempFilters.includes("Pets allowed")}/>
                                <label htmlFor="pets" onClick={(e) => handleFilters(e, "Pets allowed")}>
                                    Pet friendly
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="wifi" name="wifi" checked={tempFilters.includes("Wifi")}/>
                                <label htmlFor="wifi" onClick={(e) => handleFilters(e, "Wifi")}>
                                    WiFi
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="beach" name="beach" checked={tempFilters.includes("Beach nearby")}/>
                                <label htmlFor="beach" onClick={(e) => handleFilters(e, "Beach nearby")}>
                                    Beach
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="family" name="family" checked={tempFilters.includes("Child friendly")} />
                                <label htmlFor="family" onClick={(e) => handleFilters(e, "Child friendly")}>
                                    Family friendly
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="playground" name="playground" checked={tempFilters.includes("Playground")}/>
                                <label htmlFor="playground" onClick={(e) => handleFilters(e, "Playground")}>
                                    Playground
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="pool" name="pool" checked={tempFilters.includes("Swimming pool")}/>
                                <label htmlFor="pool" onClick={(e) => handleFilters(e, "Swimming pool")}>
                                    Swimming pool
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                        </ul>
                        <h3>Accommodation</h3>
                        <ul>
                            <li>
                                <input type="checkbox" id="glamping" name="glamping" checked={tempFilters.includes("Glamping")}/>
                                <label htmlFor="glamping" onClick={(e) => handleFilters(e, "Glamping")}>
                                    Glamping
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="camping" name="camping" checked={tempFilters.includes("Camping")}/>
                                <label htmlFor="camping" onClick={(e) => handleFilters(e, "Camping")}>
                                    Camping
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="staticCaravans" name="staticCaravans" checked={tempFilters.includes("Static Caravans")}/>
                                <label htmlFor="staticCaravans" onClick={(e) => handleFilters(e, "Static Caravans")}>
                                    Static Caravans
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="touring-caravan-pitches" name="touring-caravan-pitches" checked={tempFilters.includes("Touring caravan pitches")}/>
                                <label htmlFor="touring-caravan-pitches" onClick={(e) => handleFilters(e, "Touring caravan pitches")}>
                                    Touring caravan pitches
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="checkbox" id="lodges" name="lodges" checked={tempFilters.includes("Lodges")}/>
                                <label htmlFor="lodges" onClick={(e) => handleFilters(e, "Lodges")}>
                                    Lodges
                                    <svg viewBox="0 0 512 512">
                                        <path d="m187 434l-173-166 48-47 125 120 263-252 48 46z"/>
                                    </svg>
                                </label>
                            </li>
                        </ul>
                        <ButtonContainer>
                            <Button onClick={(e) => handleFilters(e, "close")}>Close</Button>
                            <Button className="apply" onClick={(e) => handleFilters(e, "apply")}>Apply</Button>
                        </ButtonContainer>
                    </div>
                </div>
            </Dropdown>
            <Total>{total} Parks</Total>
        </Container>
    );
};

export default Filters;