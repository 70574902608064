import React, {useState} from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Container from "../Core/Container";
import Section from "../Core/Section";
import breakpoints from "../../styles/breakpoints";
import Map from "./Map";
import Filters from "./Filters";
import ParkList from "./ParkList";
import {useStaticQuery, graphql} from "gatsby";
import Fallback from "../../assets/images/lodge.jpg";
import Mapbox from "./Mapbox";
import  "../../assets/styles/mapboxgl.css";

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`;

const MapContainer = styled.div`
  box-sizing: border-box;

  @media only screen and (min-width: ${breakpoints.lg}) {
    width: calc(50% - 1.563rem);
    margin-right: 1.563rem;
  }
`;

const PropContainer = styled.div`

  box-sizing: border-box;
  position: relative;
  padding: 0 1.25rem;

  @media only screen and (min-width: ${breakpoints.lg}) {
    width: calc(50% - 1.563rem);
    margin-left: 1.563rem;
    padding: 0;
    height: 80vh; 
  }
`;

const Search = (props) => {
    const [filters, setFilters] = useState(props.filters ? props.filters.map(a => a.title) : []);
    const [tempFilters, setTempFilters] = useState(props.filters ? props.filters.map(a => a.title) : []);
    const [region, setRegion] = useState(props.region || null);
    const [unexpectedRegion, setUnexpectedRegion] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    // static graphql query 
    const data = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityPark {
          nodes {
            id
            name
            address
            bookingComUrl
            bookingUrl
            brandImage {
              image {
                asset {
                  url
                }
              }
            }
            brandName
            county
            description
            facilities
            accomodation
            geopoint {
              lat
              lng
            }
            hoseasonsUrl
            imageUrl
            parkId
            parkUrl
            postcode
            region
            searchRanking
            slug {
              current
            }
            stUrl
            town
            searchRegion
            marketingRegion
          }
          totalCount
        }
      }
  `)

    let parks = data.allSanityPark.nodes;

    // filter parks based on region & filters
    if (region) {
      parks = parks.filter(park => {
        let searchRegion = park.searchRegion.toLowerCase();
        return searchRegion.includes(region.toLowerCase());
      });
    }

    // sort parks by searchRanking after the filtering
    parks.sort((a,b) => b.searchRanking - a.searchRanking);

    function findCommonElements3(arr1, arr2) {
      // must call the filters first, as every filter must be in array to get the AND effect of filtering props
      // e.g. arr1 shoudl be the filters we control, arr2 should be the facilities in that park
      return arr1.every(item => arr2.includes(item));
    }

    function findSomeElements3(arr1, arr2) {
      // we want an OR effect when filtering accomodation types
      // e.g. arr1 shoudl be the filters we control, arr2 should be the accomidation types in that park
      return arr1.some(item => arr2.includes(item));
    }

    if (filters.length) {
      parks = parks.filter(park => {
        // query both filters and accomodation type
        const arr =  park.facilities.concat(park.accomodation);
        return findCommonElements3(filters, arr);
      });
    }

    function handleLoad() {
      const myDiv = document.getElementById("cardList");

      if (myDiv) {
        myDiv.scrollTop = 0;
      }

      setLoading(true);
      // check if mobile
      const w = window.innerWidth;
      if ( w <= 992 && myDiv) {
        // myDiv.scrollIntoView(true);
        window.scrollTo({
          behavior: "smooth",
          top:
            myDiv.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            200,
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    function handleImageError(e) {
      e.target.onerror = null;
      e.target.src = Fallback;
    }

    function dataLayerPush (outboundEvent, outbountURL) {
      if (typeof window !== "undefined"){
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({"event": outboundEvent,  "outbound-destination": outbountURL});
      }
    }

    function openInNewTab (url, name, type) {
      if (window && url) {
        if (type === "card") {
          dataLayerPush("card-click", url);
        } else if (type === "marker") {
          dataLayerPush("map-pin-click", url);
        }
        const win = window.open(`/redirect?url=${url}&name=${name}`, "_blank");
        win.focus();
      }
    }

    return (
      <Section id={props.id ? props.id : null}>
        <Container fullWidthMobile>
          <Row>
            <Col md={12}>
              <SearchContainer>
                  <MapContainer>
                    {!region && <Map setRegion={setRegion} loading={loading} handleLoad={handleLoad}/> }
                    {region && <Mapbox region={region}
                                       parks={parks}
                                       setUnexpectedRegion={setUnexpectedRegion}
                                       openInNewTab={openInNewTab} />}
                  </MapContainer>
                  <PropContainer>
                    <Filters unexpectedRegion={unexpectedRegion} setUnexpectedRegion={setUnexpectedRegion} currentPage={currentPage} setCurrentPage={setCurrentPage} loading={loading} handleLoad={handleLoad} filters={filters} setFilters={setFilters} setRegion={setRegion} region={region} total={parks.length} tempFilters={tempFilters} setTempFilters={setTempFilters}/>
                    <ParkList openInNewTab={openInNewTab} handleImageError={handleImageError} currentPage={currentPage} setCurrentPage={setCurrentPage} parks={parks} total={parks.length} loading={loading} handleLoad={handleLoad}/>
                  </PropContainer>
              </SearchContainer>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default Search;