import React, {useEffect, useMemo, useRef, useState} from "react";
import Map, {Marker, NavigationControl, Popup} from "react-map-gl";
import styled from "styled-components";

const MapContainer = styled.div`
  height: 80vh;

  .mapboxgl-map {
    .mapboxgl-popup {
      top: -26px;

      &-content {
        padding: 0;
      }

      &-close-button {
        font-size: 1rem;
      }
    }
  }
`;

const MarkerBox = styled.div`
  background: var(--primary);
  border: 1px solid black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: background .5s ease;

  &:hover {
    background: var(--secondary);
  }
`;

const PopupContent = styled.button`
  background: transparent;
  flex-direction: row;
  display: flex;
  cursor: pointer;
  border: none;
  padding: 0.75rem;
  border-radius: 0.25rem;
  box-shadow: rgb(188 186 183) 0 1px 3px;
  outline: none;
  width: 15rem;
  overflow: hidden;

  img {
    width: 6.25rem;
    height: 5rem;
    object-fit: cover;
  }

  .copy {
    padding-left: 0.5rem;
    text-align: left;
    
    h3 {
      margin: 0;
      font-size: 0.938rem;
      line-height: 0.875rem;
      color: rgb(66, 66, 66);
    }
    
    p {
      font-size: 0.8rem;
      margin: 0.25rem 0 0;
      line-height: 0.875rem;
      color: rgb(66, 66, 66);
    }
  }
`;

const Mapbox = ({setUnexpectedRegion, region, parks, openInNewTab}) => {
    const mapRef =  useRef(null);
    const [showPopup, setShowPopup] = useState();
    const [viewState, setViewState] = React.useState({
        longitude: "-2.360403",
        latitude: "54.652741",
        zoom: 8
    });

    useMemo(() => {
    },[parks, region]);

    useEffect(() => {
        if (region.includes("south east")){
            setViewState({
                longitude: "-0.004895",
                latitude: "51.181462",
                zoom: "6"
            });
        } else if (region.includes("north east england")){
            setViewState({
                longitude: "-1.663859",
                latitude: "54.777595",
                zoom: "6"
            });
        } else if (region.includes("east england")){
            setViewState({
                longitude: "0.855416",
                latitude: "52.465095",
                zoom: "6"
            });
        } else if (region.includes("east midlands")){
            setViewState({
                longitude: "-0.135118",
                latitude: "53.333462",
                zoom: "6"
            });
        } else if (region.includes("ireland")){
            setViewState({
                longitude: "-7.541420",
                latitude: "53.518283",
                zoom: "5"
            });
        } else if (region.includes("mid wales")){
            setViewState({
                longitude: "-3.438029",
                latitude: "52.444732",
                zoom: "6"
            });
        } else if (region.includes("north wales")){
            setViewState({
                longitude: "-3.670571",
                latitude: "53.142943",
                zoom: "7"
            });
        } else if (region.includes("north west england")){
            setViewState({
                longitude: "-2.858386",
                latitude: "54.409622",
                zoom: "6"
            });
        } else if (region.includes("scotland")){
            console.log("Test")
            setViewState({
                longitude: "-3.915843",
                latitude: "56.342846",
                zoom: "6"
            });
        } else if (region.includes("south wales")){
            setViewState({
                longitude: "-3.844845",
                latitude: "51.790126",
                zoom: "6"
            });
        } else if (region.includes("south west")){
            setViewState({
                longitude: "-3.213754",
                latitude: "50.909851",
                zoom: "6"
            });
        } else if (region.includes("west midlands")){
            setViewState({
                longitude: "-2.399581",
                latitude: "52.523443",
                zoom: "6"
            });
        } else if (region.includes("yorkshire")){
            setViewState({
                longitude: "-1.085316",
                latitude: "53.804657",
                zoom: "6"
            });
        } else {
            setUnexpectedRegion(true);

            if (parks.length > 0){
                setViewState({
                    longitude: parks[0].geopoint.lng,
                    latitude: parks[0].geopoint.lat,
                    zoom: "6"
                });
            }
        }
    }, [region, parks]);

    return (
        <MapContainer>
            <Map ref={mapRef}
                 {...viewState}
                 scrollZoom={false}
                 mapStyle="mapbox://styles/snaptripkris/cl9qt9s2s002414p7fdcgep2y"
                 mapboxAccessToken="pk.eyJ1Ijoic25hcHRyaXBrcmlzIiwiYSI6ImNqa2RvejJucDB5bm4zcnM1ZDlyOHVqZnQifQ.VZTOARdDaid-d4yf2Q182g"
                 onMove={evt => setViewState(evt.viewState)}>
                <NavigationControl position="top-left"/>
                {parks && parks.map((park, index) => {
                    let link;

                    if (park.bookingComUrl) {
                        link = park.bookingComUrl;
                    } else if (park.slug) {
                        link = park.slug.current;
                    } else if (park.stUrl) {
                        link = park.stUrl;
                    } else if (park.parkUrl) {
                        link = park.parkUrl;
                    } else if (park.bookingUrl) {
                        link = park.bookingUrl;
                    } else if (park.hoseasonsUrl) {
                        link = park.hoseasonsUrl;
                    }

                    return (
                        <Marker longitude={park.geopoint.lng}
                                latitude={park.geopoint.lat}
                                anchor="bottom"
                                key={index}>
                            <MarkerBox onClick={() => setShowPopup(showPopup === index ? null : index)} className={showPopup === index && "active"}/>
                            {showPopup === index && (
                                <Popup longitude={park.geopoint.lng}
                                       latitude={park.geopoint.lat}
                                       anchor="bottom">
                                    <PopupContent onClick={() => openInNewTab(link, park.name, "marker")}>
                                        <img src={park.imageUrl} alt={park.name}/>
                                        <div className="copy">
                                            <h3>{park.name}</h3>
                                            <p>{park.region}</p>
                                        </div>
                                    </PopupContent>
                                </Popup>
                            )}
                        </Marker>
                    );
                })}
            </Map>
        </MapContainer>
    );
};

export default Mapbox;