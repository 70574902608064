import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import Loader from "./Loader";
// icons
import Icon from "../Core/Icon";

const CardList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;

    @media only screen and (min-width: ${breakpoints.lg}) {
        overflow-y: ${props => props.loading ? "hidden" : "scroll"};
        max-height: calc(80vh - 3.75rem);
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.25rem;

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
    }
`;


const Card = styled.li`
    width: 100%;
    box-shadow: 0 3px 0.2rem 0 rgb(0 0 0 / 30%);
    border-radius: .25rem;
    position: relative;

    .gatsby-image-wrapper {
        height: 23.75rem;
        width: 100%;
    }

    .property-container {
      padding: 1.25rem;
    }

    h3 {
        font-size: 1.25rem;
        margin: 0;
        line-height: 1.25;
    }

    p {
        margin-bottom: 1.25rem;
    }
    
    .region {
        max-width: 17.188rem;
    }

    .link {
        border: none;
        background: var(--secondary);
        color: var(--white);
        cursor: pointer;
        box-sizing: border-box;
        padding: 15px 45px;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        display: block;
        font-size: 0.875rem;
        margin: 0 auto;
        text-decoration: none;
        white-space: nowrap;
        z-index: 3;
        position: absolute;
        left: 50%;
        bottom: 1.25rem;
        transform: translate(-50%);
        &:hover,
        &:focus {
            background: var(--lightenButton);
        }
    }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  font-size: 1.5rem;
  color: #000;
  
  img {
    min-height: 10rem;
    height: 10rem;
    object-fit: cover;
    background: var(--grey);
  }

  .margin,
  .price {
    margin-bottom: 4.375rem;
  }
  
  .overlay-link {
    position: absolute;
    background: transparent;
    box-shadow: none;
    border: none;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
`;

const Copy = styled.div`
    padding: 1.25rem 1.25rem 4.375rem;
    position: relative;
    z-index: 10;
    height: 100%;
    
    .region,
    .description {
        font-size: .8rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 1.25rem;
        display: flex;
        flex-wrap: wrap;
    }

  li {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 1rem;
      z-index: 3;
  }

  .icon {
      height: 1rem;
      width: 1rem;
      padding: 0.5rem;
      background: var(--secondary);
      fill: var(--white);
      border-radius: 50%;
  }
  
  .brand-image {
    height: 1.875rem;
  }
`;

const NoCards = styled.div`
    height: 15rem;
    width: 100%;
    background: #D8D8D8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    flex-direction: column;

    span {
        margin-top: 0.25rem;
        font-weight: 400;
    }
`;

const Tooltip = styled.span`
    position: absolute;
    top: -1.7rem;
    z-index: 2;
    left: 50%;
    color: #fff!important;
    text-transform: capitalize;
    font-size: .75rem;
    background: rgba(0,0,0,0.8);
    width: max-content;
    padding: 0.5rem;
    border-radius: 0.4rem;
    opacity: 0;
    transition: opacity .5s ease;
    transform: translateX(-50%);

    .icons:hover > & {
        opacity: 1;
    }
`;

const Pagination = styled.div`
  margin-top: 3.125rem;
  display: flex;
  justify-content: end;
  grid-column-gap: 0.625rem;
  flex-wrap: wrap; 

  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 2.5rem;
  }

  .active {
    background: var(--secondary);
  }

  button {
    padding: 0 1rem;
    min-width: 2.625rem;
    background: var(--lightenBig);
    border: none;
    color: var(--white);
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    border-radius: 5px;
    display: block;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    height: 2.5rem;

    @media only screen and (min-width: ${breakpoints.lg}) {
        margin-top: unset;
    }

    &:disabled {
      background: #80808061;
      opacity: 0.7;

      &:hover {
        background: #80808061;   
        cursor: not-allowed;
      }
    }
    
    &:hover {
      background:  var(--secondary);
    }
  }
`;


const ParkList = ({parks, openInNewTab, total, loading, handleLoad, setCurrentPage, currentPage, handleImageError }) => {
    function sortAmenities(arr) {
        const sortedArray=[];
            
        arr.forEach(amenity => {
            let name;
                let index;
                let icon;

            switch(true) {
                case /hot-tub|hottub|hot tub/gi.test(amenity):
                    name = "hot tub";
                    icon = "icon-hot-tub";
                    index = 1;
                    break;
                case /dog |pet|dogs/gi.test(amenity):
                    name = "pet friendly";
                    icon = "icon-pet";
                    index = 2;
                    break;
                case /wifi|wi-fi|broadband/gi.test(amenity):
                    name = "wi-fi";
                    icon = "icon-thunderbolt";
                    index = 3;
                    break;
                case /garden|secluded/gi.test(amenity):
                    name = "garden";
                    icon = "icon-flower";
                    index = 4;
                    break;
                case /parking/gi.test(amenity):
                    name = "parking";
                    icon = "icon-parking";
                    index = 5;
                    break;
                default:
                    name = amenity;
                    icon = "icon-star";
                    index = 6;
            }

            sortedArray.push({
                "name":name, 
                "index":index, 
                "icon":icon, 
            });
            
        });

       return sortedArray.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    }

    function next() {
        setCurrentPage(currentPage + 1);
        handleLoad();
    }
    
    function prev() {
        setCurrentPage(currentPage - 1);
        handleLoad();
    }

    function goToPage(e) {
        setCurrentPage(parseInt(e.target.value));
        handleLoad();
    }
    
    // ========= pagination logic ==========
    const perPage = 24;
    let paginationArray =[];
    const pages = Math.ceil(total / perPage);
    const startValue = (currentPage - 1) * 24;

    // pages to show
    if ( currentPage >= 3) {
      paginationArray = [
        currentPage-2,
        currentPage-1,
        currentPage,
        currentPage+1 <= pages ? currentPage+1 : null,
        currentPage+2 <= pages ? currentPage+2 : null,
      ];
    } else if (currentPage === 2) {
      paginationArray = [
        currentPage-1,
        currentPage,
        currentPage+1 <= pages ? currentPage+1 : null,
        currentPage+2 <= pages ? currentPage+2 : null,
      ];
    } else {
      paginationArray = [
        currentPage,
        currentPage+1 <= pages ? currentPage+1 : null,
        currentPage+2 <= pages ? currentPage+2 : null,
      ];
    }

    parks = parks.slice(startValue, startValue + perPage);

    return (
        <>
        {parks.length > 0 ?
            <CardList loading={loading} id="cardList">
                <Grid>
                    {parks.map((park, index) => {
                        let link = "";
                        let sortedArray;
                        // add correct link based on what's present in the BE

                        if (park.bookingComUrl) {
                            link = park.bookingComUrl;
                        } else if (park.slug) {
                            link = park.slug.current;
                        } else if (park.stUrl) {
                            link = park.stUrl;
                        } else if (park.parkUrl) {
                            link = park.parkUrl;
                        } else if (park.bookingUrl) {
                            link = park.bookingUrl;
                        } else if (park.hoseasonsUrl) {
                            link = park.hoseasonsUrl;
                        }
                        // sort amentities into popularity brackets
                        sortedArray = sortAmenities(park.facilities);
                        sortedArray = sortedArray.slice(0, 4);

                        // remove PPC error where non-secure links are considered malware
                        link = link.startsWith("https") ? link : false;

                        return (
                            <Card key={index} className="grid-item">
                                <CardContainer>
                                    <button onClick={() => openInNewTab(link, park.name, "card")} className="overlay-link"/>
                                    <img src={park.imageUrl} alt={park.name} onError={(e)=> handleImageError(e)}/>
                                    <Copy>
                                        <h3>{park.name}</h3>
                                        {/* {park.brandImage && <img className="brand-image" src={park.brandImage.image.asset.url} />} */}
                                        <p className="region">
                                            {park.region}
                                        </p>
                                        <ul>
                                            {sortedArray && sortedArray.map((amenity, amenityIndex) => {
                                                return (
                                                <li className="icons" key={amenityIndex} title={amenity.name}>
                                                    <Tooltip>{amenity.name}</Tooltip>
                                                    <Icon name={amenity.icon} />
                                                </li>
                                                );
                                            })}
                                        </ul>
                                        {park.description && <p className="description">{park.description}</p> }
                                        <button onClick={() => openInNewTab(link, park.name, "card")} className="link">Learn more</button>
                                    </Copy>
                                </CardContainer>
                            </Card>
                        );
                    })}
                </Grid>
                <Pagination>
                    <button onClick={() => prev()} disabled={currentPage === 1}>Prev</button>
                    {paginationArray && paginationArray.filter(Number).map(num => (
                          <button onClick={(e) => goToPage(e)} className={currentPage === num ? "active" : ""} key={num} value={num}>{num}</button>
                    ))}
                    <button onClick={() => next()} disabled={currentPage === pages}>Next</button>
                </Pagination>
                {loading && <Loader />}
            </CardList>
            :
            <NoCards>
                No matches
                <span>Try removing filters.</span>
            </NoCards>}
        </>
    );
};

export default ParkList;